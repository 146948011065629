// Set unique values for the array "ar"
function uniqueArray(ar) {
	var j = {};

	ar.forEach(function (v) {
		j[v + '::' + typeof v] = v;
	});

	return Object.keys(j).map(function (v) {
		return j[v];
	});
}

// Equalize blocks height by setting the same min-height value
function equalizeBlocks() {
	var blockArray = $('[data-eq]').map(function () {
		return $(this).data('eq');
	}).get();
	var blocks = uniqueArray(blockArray);

	blocks.forEach(function (elem) {
		var prev = 0;
		$('[data-eq="' + elem + '"]')
			.css('min-height', '')
			.each(function () {
				if ($(this).outerHeight() > prev) {
					prev = $(this).outerHeight();
				}
			})
			.css('min-height', prev + 'px');
	});
}

// Square blocks height
function squareBlocks() {
	$('[data-square]').css('min-height', '');
	$('[data-square]').each(function (ev) {
		$(this).css('min-height', $(this).outerWidth());
	});
}

// ----------------------------------------- //
// DOM READY
$(document).ready(function ($) {
	"use strict";

	// Block Ref - Slider
	var $slider = $('.slider-ref');
	var $progressBar = $('.progress__item');
	var $progressItem = $('.progress__item');

	$slider.on('init', function (event, slick) {
		var calc = (1 / (slick.slideCount)) * 100;
		$progressItem.css('width', calc + '%');
	});

	$slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		//var calc = ( (nextSlide) / (slick.slideCount - 1) ) * 100;
		var calc = nextSlide * (1 / (slick.slideCount)) * 100;
		$progressBar.css('left', calc + '%');
	});
});

// WINDOW LOAD
$(window).on('load', function () {

/*	var acc = document.getElementsByClassName("accordion");
	var i;

	for (i = 0; i < acc.length; i++) {
		acc[i].addEventListener("click", function() {
			this.classList.toggle("active");
			var panel = this.nextElementSibling;
			if (panel.style.display === "block") {
				panel.style.display = "none";
			} else {
				panel.style.display = "block";
			}
		});
	}*/

	$.fn.createlogo();

	// Activate Animation On Scroll
	AOS.init();

	equalizeBlocks();
	squareBlocks();

	// Fade in slider on page load
	$('.pp-slider').on('init', function (event, slick) {
		$(this).css({
			'opacity': 1,
			'visibility': 'visible'
		});
	});

	// Home Slider
	$('.slider-home').slick({
		infinite: true,
		fade: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		speed: 1000,
	});

	$('.slider-ref').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 3000,
		speed: 1000,
		dots: true,
		arrows: false,
	});

	$(".actus-content-mobile").slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 3000,
		speed: 1000,
		dots: false,
		arrows: false,
	});
	// TIMELINE YEAR  ---------------------------------------------------------------
	$('.story__slider').slick({
		centerMode: false,
		centerPadding: '0',
		initialSlide: 0,
		slidesToShow: 3,
		nextArrow: '<i class="story__next fa fa-long-arrow-right"></i>',
		prevArrow: '<i class="story__prev fa fa-long-arrow-left"></i>',
		draggable: false,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					draggable: true,
					slidesToShow: 2

				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	var localSlider = $('.local-single-slider__image').length;

	if (localSlider > 1) {
		$('.local-single-slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			asNavFor: '.local-single-slider-nav',
			prevArrow: $('.prev-slider'),
			nextArrow: $('.next-slider'),
			responsive: [{
				breakpoint: 992,
				settings: {
					arrows: false
				}
			}]
		});

		$('.local-single-slider-nav').slick({
			slidesToShow: localSlider - 1,
			slidesToScroll: 1,
			asNavFor: '.local-single-slider',
			focusOnSelect: true,
			responsive: [{
				breakpoint: 992,
				settings: {
					arrows: false
				}
			}]
		});

	}

	//------Fix for last slide jump -----------------------

	$('.story__slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		var direction = "left";

		if (Math.abs(nextSlide - currentSlide) == 1) {
			direction = (nextSlide - currentSlide > 0) ? "right" : "left";
		}
		else {
			direction = (nextSlide - currentSlide > 0) ? "left" : "right";
		}

		var theNextSlide = $(".slick-active.slick-center").next(".slick-slide");
		var thePrevSlide = $(".slick-active.slick-center").prev(".slick-slide");

		if (direction === "right" && $(theNextSlide).attr("tabindex") == "-1") {
			$(theNextSlide).addClass("slickcenteryearfix");

			setTimeout(function () {
				$(theNextSlide).removeClass("slickcenteryearfix");
			}, 500);
		}
		if (direction === "left" && $(thePrevSlide).attr("tabindex") == "-1") {
			$(thePrevSlide).addClass("slickcenteryearfix");

			setTimeout(function () {
				$(thePrevSlide).removeClass("slickcenteryearfix");
			}, 500);
		}
	});

});

// WINDOW RESIZE
$(window).resize(function () {
	squareBlocks();
	equalizeBlocks();
});

// MENU FONT RESIZER

/*
var min = 8;
var max = 26;
var reset = $('p').css('fontSize');
var elm = $('p');
var elm_li = $('li');
var size = str_replace(reset, 'px', '');

//increase font size
$('a.fontSizePlus').click(function () {
		if (size < max) {
				size++;
				elm.css({'fontSize': size});
				elm_li.css({'fontSize': size});
		}
		return false;
});

//decrease font size
$('a.fontSizeMinus').click(function () {
		if (size > min) {
				size--;
				elm.css({'fontSize': size});
				elm_li.css({'fontSize': size});
		}
		return false;
});

function str_replace(haystack, needle, replacement) {
		var temp = haystack.split(needle);
		return temp.join(replacement);
}
*/


var $affectedElements = $("h1, h2, h3, h4, p, span, a"); // Can be extended, ex. $("div, p, span.someClass")

// Storing the original size in a data attribute so size can be reset
$affectedElements.each(function () {
	var $this = $(this);
	$this.data("orig-size", $this.css("font-size"));
});

$("#font-up").click(function (event) {
	event.preventDefault();
	changeFontSize(1);
});

$("#font-down").click(function (event) {
	event.preventDefault();
	changeFontSize(-1);
});

function changeFontSize(direction) {
	$affectedElements.each(function () {
		var $this = $(this);
		$this.css("font-size", parseInt($this.css("font-size")) + direction);
	});
}

var $searchBar = $("#nForm");
var $searchItem = $("#searchItem");

$searchItem.click(function (event) {
	$searchBar.css("display", "block");
	$searchItem.css("display", "none");
});

const burger = document.querySelector('.show_menu');
const menu = document.querySelector('.menu-content');
burger.addEventListener("click", show_menu);

function show_menu() {
	menu.classList.toggle("active_menu");
	burger.classList.toggle("opacity-burger");
}

$(".faq-question").on('click', function (e){
	e.preventDefault();
	$(this).toggleClass('active');
	$(this.nextElementSibling).toggleClass('active');
})
